//@flow

import * as React from "react";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import BlockIcon from "./block_icon.png";

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.palette.background.primary};
`;

const Container = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

const Image = styled.img`
  height: 160px;
  margin-bottom: 10px;
`;

const StyledLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = () => (
  <Typography variant="body2" color="text.secondary" style={{ marginTop: "8px" }}>
    E-mail: <StyledLink href="mailto:sales@sputnik.systems">sales@sputnik.systems</StyledLink>
    <br />
    Телеграм-бот: <StyledLink href="https://t.me/sputnik_sales_bot">@sputnik_sales_bot</StyledLink>
    <br />
    Телефон: <StyledLink href="tel:88005115232">8 800 511-52-32</StyledLink>
  </Typography>
);

function BlockedPage() {
  const title = "Кабинет заблокирован";
  const message = `Ваш кабинет был заблокирован в связи с отрицательным балансом и отсутствием его пополнения в течение 5 дней.

Обращаем ваше внимание, что видеозаписи будут записываться и предоставляться пользователям.

Чтобы снять блокировку и пополнить баланс, обратитесь в Коммерческий отдел Спутник.`;

  return (
    <Wrapper>
   
      <Container>
        <Image src={BlockIcon} alt="Blocked Account" />
        <Typography align="center" variant="h6" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ whiteSpace: "pre-line" }}>
          {message}
        </Typography>
        <ContactInfo />
      </Container>
    </Wrapper>
  );
}

export default withRouter(BlockedPage);
