// import React...
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './pages/Login'
import Register from './pages/Register'
import Invite from './pages/Invite'
import Welcome from './pages/Welcome'
import ForbiddenPage from './pages/403'
import BlockedPage from './pages/423'
import Agreement from './pages/Agreement'
import License from './pages/License'

import AppTemplate from './templates/AppTemplate'

const App = () => (
  <AppTemplate>
    <Switch>
      <Route path="/license" component={License} />
      <Route path="/agreement" component={Agreement} />
      <Route path="/403" component={ForbiddenPage} />
      <Route path="/423" component={BlockedPage} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/invite/:token" component={Invite} />
      <Route path="/register" component={Register} />
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  </AppTemplate>
)

export default App
